import Layout from "../common/layout"
import DetailMediaBestManagedCompanies from "../company/DetailMedia/DetailMediaBestManagedCompanies"
import Head from "../common/Head"
import { useFormatter } from "helpers/i18n"
import BestManagedCompaniesInVietnam2024 from "images/Company/About/BestManagedCompaniesInVietnam2024.png"

const PressRelease = () => {
  const { __ } = useFormatter()
  return (
    <>
      <Head
        showCaptcha={false}
        title={__({ defaultMessage: "Press Release" })}
        description={__({
          defaultMessage:
            "OnPoint is a 2024 winner of Vietnam’s Best Managed Companies 2024",
        })}
        image={BestManagedCompaniesInVietnam2024}
      />
      <Layout>
        <DetailMediaBestManagedCompanies />
      </Layout>
    </>
  )
}

export default PressRelease
